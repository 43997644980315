<template>
  <div>
    <div class="login_page">
    <div class="login_container">
     <img class="login_bgImg" src="../../../src/assets/login/bg_img.png" /> 

        <div class="login_box">
       
       <img class="login_title" src="../../../src/assets/login/login_title.png" />


       <div class="login_form">

        <img class="form_img" src="../../../src/assets/login/Input_bg.png" />

 

      <el-form :model="form" class="form_map"   ref="ruleLogin" v-if="!mode">
    
   <el-form-item >
    <el-input v-model="mapData.userName" class="form_input"  size="large" placeholder="请输入您的用户名">
          <template #prefix>
          <img class="input_logo" src="../../../src/assets/login/user_logo.png" />
        </template>
    </el-input>
   </el-form-item>

   <el-form-item >
    <el-input v-model="mapData.password" class="form_input" show-password  size="large" placeholder="请输入您的密码">
        <template #prefix>
          <img class="input_logo" src="../../../src/assets/login/user_pwd.png" />
        </template>
    </el-input>
   </el-form-item>

    <el-form-item >
    <div class="operation_pwd flex_between" >
    <div class="flex_start"> <el-checkbox v-model="checkPwd"  size="large" /><span @click="checkboxPwd">记住密码</span></div>
    <div >忘记密码</div>
    </div>
    </el-form-item>

       <el-form-item >
     <el-button class="sub_btn" @click="login">登录</el-button>
   </el-form-item>


    <el-form-item >
    <div class="flex_center wx_content" @click="change">
    <img class="login_wx" src="../../../src/assets/login/login_wx.png" />
    <span >微信扫码登录</span>
    </div>
    </el-form-item>
    


    </el-form>

    <div class="wx_login" v-else>
        <span>请使用微信扫一扫登录</span>
        <img class="qr_code" src="/" alt="" />
        <div class="login_pwd flex_center" @click="change"><img src="../../../src/assets/login/user_pwd.png"  /><span>账号密码登录</span></div>

    </div>

       </div>
     </div>
   
    </div>
    </div>
  </div>
</template>

<script>
import {reactive,ref} from 'vue'
import {  useRouter } from 'vue-router'
export default {
  name: 'login',
  props: {

  },
  setup(){
  let mapData=reactive({
      userName:'',
      password:'',
    })
 let checkPwd=ref(false)
 let mode=ref(false)
 const router =useRouter()
 const change=()=>{
    mode.value=!mode.value
    }
 const checkboxPwd=()=>{
    checkPwd.value=!checkPwd.value
    }
    const login=()=>{
      router.replace({path:'/workbench',replace: true,query: {}});
    }
    
    return{
      mapData,
      checkPwd,
      change,
      mode,
      checkboxPwd,
      login

    }

  }
}
</script>

<style lang="scss" scoped>
 .login_page{
    width: 100%;
    height: 100%;
    background: #090A1F;
    position: fixed;
    top: 0;
    left: 0;    
    font-size: 16px;
    } 
    .login_container{
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
     .login_box{
       width: 731px;
      display: flex;
      align-content: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      .login_title{
        width: 520px;
        height: auto;
        margin-bottom: 35px;
        z-index: 15;
      }

     .login_form{
       width: 731px;
       height: 378px;
       position: relative;
       .form_img{
          width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 12;
        
       }
       .form_map{
        width: 100%;
        height: 100%;
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;

    &:deep(.el-form-item){
        margin-bottom: 0;
    }
    .form_input{
        width: 320px;
        height: 48px;
        margin-top: 32px;
        z-index: 13;

      }
   .operation_pwd{
         width: 320px;
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        margin-top: 16px;
        z-index: 13; 
                cursor: pointer;

            &:deep(.el-checkbox){
                margin-right: 15px;
            
            }
      }
    .sub_btn{
    width: 320px;
    height: 50px;
    background: linear-gradient(180deg, #3090E4 0%, #09D3D5 100%);
    border-radius:4px;
    z-index: 13;
    font-size: 24px;
    margin-top: 22px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #FFFFFF;
    }
    .wx_content{
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        margin-top: 18px;
                cursor: pointer;

        z-index: 13;
        & span{
             margin-left: 8px;
        }
    }
    
       }
         
         .wx_login{
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-wrap: wrap;
            font-size: 18px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
             padding: 20px 0;
             padding-top: 38px;
            color: #FFFFFF;
            &>span{
                // width: 100%;
                margin-bottom: 25px;
                z-index: 13;
            }

            .qr_code{
                width: 200px;
                height: 200px;
                z-index: 13;

            }
            .login_pwd{
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                z-index: 13;
                margin-top: 40px;
                cursor: pointer;
                &>img{
                    // width: 18px;
                    // height: 18px;
                    margin-right: 8px
                }

            }

         }
        
      }
        }
    
      .login_bgImg{
        position: absolute;
        bottom: 0;
        width: 1208px;
        height: auto;
        left: 50%;
        margin-left: -604px;

      }

        

    }
</style>
